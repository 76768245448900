import Cropper from 'cropperjs';
import SlimSelect from 'slim-select'
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {

  static targets = ['phoneNumberForm', 'certificationForm', 'linkForm', 'mlsIdForm', 'feederMarketForm', 'addItemButton', 'disabledAddItemButton', 'subdivisions', 'iso3166Field', 'testimonialForm', 'languageForm', 'searchSelect', 'additionalEmailForm']

  static values = {
    phoneTableSrc: String,
    certificationTableSrc: String,
    testimonialTableSrc: String,
    additionalEmailTableSrc: String,
    feederMarketTableSrc: String,
    languageTableSrc: String,
    linkTableSrc: String,
    mlsIdTableSrc: String,
    hasSubdivisions: Array
  }

  disableAddItemButton() {
    this.addItemButtonTarget.classList.add('hidden');
    this.disabledAddItemButtonTarget.classList.remove('hidden');
  }
  enableAddItemButton() {
    this.addItemButtonTarget.classList.remove('hidden');
    this.disabledAddItemButtonTarget.classList.add('hidden');
  }

  enterEditMode() {
    this.element.classList.add('editing');
    this.disableAddItemButton();
  }

  exitEditMode() {
    this.element.classList.remove('editing');
  }

  updatePhoneKind(e) {
    let newExtensionInput = e.target.parentNode.parentNode.querySelector('.extension-wrapper input.phone-extension');
    if (['mobile', 'fax'].includes(e.target.value )) {
      newExtensionInput.classList.add('hidden');
      newExtensionInput.value = '';
    } else {
      newExtensionInput.classList.remove('hidden');
    }
  }

  phoneNumberFormTargetConnected(element) {
    let that = this;
    document.addEventListener('keypress', closeFormRow);
    document.addEventListener('turbo:before-cache', function() {
      document.removeEventListener('keypress', closeFormRow);
    });

    function closeFormRow(event) {
      if (event.key == 'Escape') {
        let newPhoneFrame = document.getElementById('new_phone_number');
        let phoneTableFrame = document.getElementById('phone_numbers_table');
        newPhoneFrame.src = that.phoneTableSrcValue;
        phoneTableFrame.src = that.phoneTableSrcValue;
        that.enableAddItemButton();
      }
    }

  }

  certificationFormTargetConnected(element) {
    let that = this;
    document.addEventListener('keypress', closeFormRow);
    document.addEventListener('turbo:before-cache', function() {
      document.removeEventListener('keypress', closeFormRow);
    });

    function closeFormRow(event) {
      if (event.key == 'Escape') {
        let newCertificationFrame = document.getElementById('new_certification');
        let certificationTableFrame = document.getElementById('certifications_table');
        newCertificationFrame.src = that.certificationTableSrcValue;
        certificationTableFrame.src = that.certificationTableSrcValue;
        that.enableAddItemButton();
      }
    }
  }

  feederMarketFormTargetConnected(element) {
    let that = this;
    document.addEventListener('keypress', closeFormRow);
    document.addEventListener('turbo:before-cache', function() {
      document.removeEventListener('keypress', closeFormRow);
    });

    function closeFormRow(event) {
      if (event.key == 'Escape') {
        let newFeederMarketFrame = document.getElementById('new_feeder_market');
        let feederMarketTableFrame = document.getElementById('feeder_markets_table');
        newFeederMarketFrame.src = that.feederMarketTableSrcValue;
        feederMarketTableFrame.src = that.feederMarketTableSrcValue;
        that.enableAddItemButton();
      }
    }

  }

  testimonialFormTargetConnected(element) {
    let that = this;
    document.addEventListener('keypress', closeFormOnEsc);
    document.addEventListener('turbo:before-cache', function() {
      document.removeEventListener('keypress', closeFormOnEsc);
    });

    function closeFormOnEsc(event) {
      if (event.key == 'Escape') {
        let table = document.getElementById('testimonial_table');
        table.src = that.testimonialTableSrcValue;
      }
    }
  }

  languageFormTargetConnected(element) {
    let that = this;
    document.addEventListener('keypress', closeFormOnEsc);
    document.addEventListener('turbo:before-cache', function() {
      document.removeEventListener('keypress', closeFormOnEsc);
    });

    function closeFormOnEsc(event) {
      if (event.key == 'Escape') {
        let table = document.getElementById('languages_table');
        table.src = that.languageTableSrcValue;
        that.enableAddItemButton();
      }
    }
  }

  linkFormTargetConnected(element) {
    let that = this;
    document.addEventListener('keypress', closeFormOnEsc);
    document.addEventListener('turbo:before-cache', function() {
      document.removeEventListener('keypress', closeFormOnEsc);
    });

    function closeFormOnEsc(event) {
      if (event.key == 'Escape') {
        let table = document.getElementById('links_table');
        let newLinkRow = document.getElementById('new_link');
        table.src = that.linkTableSrcValue;
        that.exitEditMode();
        that.enableAddItemButton();
        newLinkRow.innerHTML = '';
      }
    }
  }

  mlsIdFormTargetConnected(element) {
    let that = this;
    document.addEventListener('keypress', closeFormOnEsc);
    document.addEventListener('turbo:before-cache', function() {
      document.removeEventListener('keypress', closeFormOnEsc);
    });

    function closeFormOnEsc(event) {
      if (event.key == 'Escape') {
        let table = document.getElementById('mls_id_table');
        table.src = that.mlsIdTableSrcValue;
        that.exitEditMode();
        that.enableAddItemButton();
      }
    }
  }

  additionalEmailFormTargetConnected(element) {
    let that = this;
    document.addEventListener('keypress', closeFormRow);
    document.addEventListener('turbo:before-cache', function() {
      document.removeEventListener('keypress', closeFormRow);
    });

    function closeFormRow(event) {
      if (event.key == 'Escape') {
        let newCertificationFrame = document.getElementById('new_additional_email');
        let additionalEmailTableFrame = document.getElementById('additional_emails_table');
        newCertificationFrame.src = that.additionalEmailTableSrcValue;
        additionalEmailTableFrame.src = that.additionalEmailTableSrcValue;
        that.enableAddItemButton();
      }
    }
  }

  searchSelectTargetConnected(element) {
    new SlimSelect({ select: element });
  }

  imageDroped(event) {
    this.displaySpinner(event);
    this.submitFormFromInputWithTurbo(event);
  }

  profileImageDropped(e) {
    var that = this;

    if (e) {
      var file = e.target.files[0];
      var reader  = new FileReader();

      that.imageController.destroyCropper();

      reader.onload = function(e)  {
        var image = document.querySelector("img#image-to-crop");
        image.src = e.target.result;
        that.imageController.cropImage(image, false);

      }
      reader.readAsDataURL(file);
    } else {
      var image = document.querySelector("img#image-to-crop");
      that.imageController.cropImage(image);
    }
    this.modalController.open();
  }

  displaySpinner(event) {
    let element = event.target;
    let spinners = document.getElementsByClassName(element.dataset.spinner); //the div where we will show image

    [].forEach.call(spinners, (spinner) => {
      spinner.classList.remove('hidden');
    });
  }

  confirmRemovePhoto(event) {
    if (confirm("Are you sure that you want to delete this photo?") == true) {
      this.displaySpinner(event);
      this.submitFormFromInputWithTurbo(event);
    } else {
      event.target.checked = false;
    }
  }

  submitFormFromInputWithTurbo(event) {
    event.target.form.dispatchEvent(new CustomEvent('submit', { bubbles: true }))
  }

  updateSubdivisions(e) {
    let countryCode = e.target.value;
    this.updateISO3166(countryCode);

    if (this.hasSubdivisionsValue.includes(countryCode)) {
      this.subdivisionsTarget.classList.remove('hidden');

      this.hasSubdivisionsValue.forEach((code) => {
        if (code == countryCode) {
          document.getElementById(countryCode+'-subdivisions').classList.remove('hidden');
        } else {
          let subdivision = document.getElementById(code+'-subdivisions');
          subdivision.classList.add('hidden');
          subdivision.value = '';
        }
      })

    } else {
      this.subdivisionsTarget.classList.add('hidden');

      this.hasSubdivisionsValue.forEach((code) => {
        let subdivision = document.getElementById(code+'-subdivisions');
        subdivision.classList.add('hidden');
        subdivision.value = '';
      })
    }

  }

  submitProfilePhotoForm() {
    document.querySelector('form#profile-form').submit();
  }

  selectState(e) {
    this.updateISO3166(e.target.value);
  }

  updateISO3166(value) {
    this.iso3166FieldTarget.value = value;
  }

  updateMlsIdSource(event) {
    let selectedOption = event.srcElement[event.target.selectedIndex];
    let sourceInput = document.getElementById('mls_id_source');
    sourceInput.value = selectedOption.dataset.source;
  }

  enableSubmit(e) {
    let form = e.target.form;
    let submitButton = form.querySelector('button.disabled, input.disabled[type=submit]');
    if (submitButton) {
      submitButton.classList.remove('disabled');
      submitButton.disabled = false;
    }
  }

  openCropModal() {
    this.profileImageDropped();
  }

  get imageController() {
    var imageContainerElement = document.getElementById('image-crop-container')
    return this.application.getControllerForElementAndIdentifier(imageContainerElement, 'image');
  }

  get modalController() {
    var modalContainerElement = document.getElementById('modal')
    return this.application.getControllerForElementAndIdentifier(modalContainerElement, 'modal');
  }

}

