import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  
  static targets = ['window']
  
  open() {
    this.windowTarget.classList.remove('hidden');
  }
  
  close() {
    this.windowTarget.classList.add('hidden');
  }
  
}
