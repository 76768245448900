import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="menu"
export default class extends Controller {

  static targets = ['mobileOpen', 'mobileClose', 'mobileMenu']

  toggleMobileMenu() {
    // Open/close menu
    this.mobileMenuTarget.classList.toggle('hidden');
    this.mobileMenuTarget.classList.toggle('block');

    // Show/Hide menu open button
    this.mobileOpenTarget.classList.toggle('hidden');
    this.mobileOpenTarget.classList.toggle('block');

    // Show/Hide menu close button
    this.mobileCloseTarget.classList.toggle('hidden');
    this.mobileCloseTarget.classList.toggle('block');
  }

  mobileMenuTargetConnected(element) {
    var submenues = element.querySelectorAll('.dropdown');

    [].forEach.call(submenues, (submenu) => {
      submenu.addEventListener('click', () => {

        // Close all other menus
        [].forEach.call(submenues, (sub) => {
          if (submenu != sub) {
            sub.classList.remove('open');
          }
        });

        // Toggle Menu open/close
        submenu.classList.toggle('open');
      });
    });

  }


}
