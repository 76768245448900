import List from 'list.js'
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="list"
export default class extends Controller {
  
  static targets = ['availableFeaturedListings']
    
  availableFeaturedListingsTargetConnected(element) {
    var options = {
      valueNames: [ 'listing-title', 'listing-location' ],
      page: 10,
      visibleItems: ".summary",
      pagination: {
        // outerWindow: 1,
        // innerWindow: 1,
        includeDirectionLinks: true,
        leftDirectionText: '← Previous',
        rightDirectionText: 'Next →',
        item: "<li><span class='page link'></span></li>"
      }
    };
    
    var listObj = new List('listId', options);
  }
}
